<!--打包单详情-->
<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button class="itemBtn btnColor" @click="kehugenzong"
          >客服追踪</el-button
        >
        <!-- <el-button v-if="hasPermiss('bggl_zongHeChaXun:express:query')" class="itemBtn btnColor" @click="queryTrack">
          查询轨迹
        </el-button>
        <el-button class="itemBtn btnColor" @click="kehujiashou(1)">客户加收</el-button> -->
        <!-- <el-button class="itemBtn btnColor" @click="kehujiashou(2)">代理加收</el-button> -->
        <el-button class="itemBtn btnColor" @click="uploadFIle()"
          >上传附件</el-button
        >
      </div>
      <div class="singleinpbtn wtl_queryBoxNew">
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">
            {{ MoreConditions }}
          </div>
        </el-tooltip>
      </div>
    </div>

    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp_time">
            <div class="timeBox queryItemTime">
              <el-select
                class="queryInput"
                v-model="timeType"
                filterable
                @change="timestatus"
                placeholder="请选择时间类型"
              >
                <el-option
                  v-for="item in timeTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="right_inpu wtl_timeStyle">
                <el-date-picker
                  class="input"
                  value-format="yyyy-MM-dd"
                  v-model="queryData.startTime"
                  type="date"
                  placeholder="选择日期"
                  @change="queryBtn_ok"
                ></el-date-picker>
                <div>至</div>
                <el-date-picker
                  class="input"
                  value-format="yyyy-MM-dd"
                  v-model="queryData.endTime"
                  type="date"
                  placeholder="选择日期"
                  @change="queryBtn_ok"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">打包批次号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.billPackNumbers"
                clearable
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">出库单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.outStockNumbers"
                clearable
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">转运单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.channelNumbers"
                clearable
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">团号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.invitationCode"
                clearable
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>

          <div class="singleinp" @click="changeVip">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberId"
                readonly
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">打包状态:</div>
            <div class="singleinpright">
              <el-select
                class="input"
                v-model="queryData.packStatus"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in packageStatusArr"
                  :key="index"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">运输方式:</div>
            <div class="singleinpright">
              <el-select
                class="input"
                v-model="queryData.channelTransportId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in yunshuList"
                  :key="index"
                  :label="item.transportName"
                  :value="item.transportId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- <div class="item_left">
          <div class="singleinpbtn">
            <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search">查询</el-button>
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div> -->
      </div>
    </el-form>

    <div class="contentInner">
      <!-- 左边列表 -->
      <div class="rightInner">
        <el-table
          :data="expressData"
          border
          ref="mytable"
          @row-dblclick="dblclick"
          highlight-current-row
          stripe
          size="small"
          :height="tableHeigth"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            align="center"
            :show-overflow-tooltip="true"
            label="打包批次号"
            width="149"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="双击查看详情"
                placement="top-start"
              >
                <div
                  :class="scope.row.billPackId == packageId ? 'activeLeft' : ''"
                >
                  {{ scope.row.billPackNumber }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          layout="total,prev, next"
          prev-text="上一页"
          next-text="下一页"
          :current-page="pageStart"
          :total="pageCount"
          :page-size="pageTotal"
          @size-change="psc"
          @current-change="pcc"
        >
        </el-pagination>
      </div>
      <!-- 右边 -->
      <div class="leftInner">
        <el-tabs
          v-model="activeName"
          @tab-click="clickActiveName"
          type="card"
          stretch
        >
          <el-tab-pane label="包裹信息" name="first">
            <el-descriptions title="基础信息" :column="4" border size="medium">
              <el-descriptions-item label="打包批次号">{{
                packageDetail.baseInfoVo.billPackNumber
              }}</el-descriptions-item>
              <el-descriptions-item label="会员号"
                >{{ packageDetail.baseInfoVo.memberId }}
              </el-descriptions-item>
              <el-descriptions-item label="会员名称"
                >{{ packageDetail.baseInfoVo.memberName }}
              </el-descriptions-item>
              <el-descriptions-item label="当前仓库"
                >{{ packageDetail.baseInfoVo.currentStorageName }}
              </el-descriptions-item>
              <el-descriptions-item label="运输方式"
                >{{ packageDetail.baseInfoVo.transportName }}
              </el-descriptions-item>
              <el-descriptions-item label="渠道"
                >{{ packageDetail.baseInfoVo.channelName }}
              </el-descriptions-item>
              <el-descriptions-item label="渠道代理"
                >{{ packageDetail.baseInfoVo.channeAgentlName }}
              </el-descriptions-item>
              <el-descriptions-item label="转单号"
                >{{ packageDetail.baseInfoVo.channelNumber }}
              </el-descriptions-item>
              <el-descriptions-item label="快递数量"
                >{{ packageDetail.baseInfoVo.packageCount }}
              </el-descriptions-item>
              <el-descriptions-item label="打包件数"
                >{{ packageDetail.baseInfoVo.packSureCount }}
              </el-descriptions-item>
              <el-descriptions-item label="打包重量"
                >{{ packageDetail.baseInfoVo.totalWeight }}
              </el-descriptions-item>
              <el-descriptions-item label="打包材积"
                >{{ packageDetail.baseInfoVo.dataVolume }}
              </el-descriptions-item>
              <el-descriptions-item label="打包体积"
                >{{ packageDetail.baseInfoVo.totalVolume }}
              </el-descriptions-item>
              <el-descriptions-item label="申请打包备注"
                >{{ packageDetail.baseInfoVo.billPackComment }}
              </el-descriptions-item>
              <el-descriptions-item label="打包确认备注"
                >{{ packageDetail.baseInfoVo.billStorepackComment }}
              </el-descriptions-item>
              <el-descriptions-item label="班次号"
                >{{ packageDetail.baseInfoVo.shiftNumber }}
              </el-descriptions-item>
              <el-descriptions-item label="提货方式"
                >{{ packageDetail.baseInfoVo.handoverModeShow }}
              </el-descriptions-item>
              <el-descriptions-item label="客户单号"
                >{{ packageDetail.baseInfoVo.customerNumber }}
              </el-descriptions-item>
              <el-descriptions-item label="合包单号"
                >{{ packageDetail.baseInfoVo.billClosePackageNumber }}
              </el-descriptions-item>
              <el-descriptions-item label="在库状态"
                >{{ packageDetail.baseInfoVo.stockStatusShow }}
              </el-descriptions-item>
              <el-descriptions-item label="当前仓库"
                >{{ packageDetail.baseInfoVo.storageName }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions
              title="收货人信息"
              :column="4"
              border
              size="medium"
            >
              <el-descriptions-item label="收件人">{{
                packageDetail.addressVo.fullName
              }}</el-descriptions-item>
              <el-descriptions-item label="公司名称">{{
                packageDetail.addressVo.companyName
              }}</el-descriptions-item>
              <el-descriptions-item label="联系电话">{{
                packageDetail.addressVo.tel
              }}</el-descriptions-item>
              <el-descriptions-item label="联系手机">{{
                packageDetail.addressVo.phone
              }}</el-descriptions-item>
              <el-descriptions-item label="国家/地区"
                >{{ packageDetail.addressVo.areaCountryName }}
              </el-descriptions-item>
              <el-descriptions-item label="州/省"
                >{{ packageDetail.addressVo.provinceName }}
              </el-descriptions-item>
              <el-descriptions-item label="城市">{{
                packageDetail.addressVo.cityName
              }}</el-descriptions-item>
              <el-descriptions-item label="邮政编号">{{
                packageDetail.addressVo.postalCode
              }}</el-descriptions-item>
              <el-descriptions-item label="详细地址">{{
                packageDetail.addressVo.address
              }}</el-descriptions-item>
            </el-descriptions>

            <div>
              <div class="titles1">打包明细</div>
              <el-table
                show-summary
                :data="packageDetail.descPackageVo"
                border
                stripe
                size="small"
                style="width: 100%"
              >
                <!-- <el-table-column type="index" label="序号" width="50"></el-table-column> -->
                <el-table-column
                  prop="serialNumber"
                  align="center"
                  label="包号"
                  :show-overflow-tooltip="true"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="weight"
                  align="center"
                  :show-overflow-tooltip="true"
                  label="打包重量(kg)"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="volume"
                  align="center"
                  label="打包体积(m³)"
                  :show-overflow-tooltip="true"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop=""
                  align="center"
                  label="外箱尺寸(cm)"
                  :show-overflow-tooltip="true"
                  min-width="160"
                >
                  <template slot-scope="scoped">
                    <div>
                      {{ scoped.row.length }}*{{ scoped.row.width }}*{{
                        scoped.row.height
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cccccc"
                  align="center"
                  label="图片"
                  :show-overflow-tooltip="true"
                  min-width="120"
                >
                  <template slot-scope="scoped">
                    <el-button
                      @click="getImgsId(scoped.row.stockPackDescId)"
                      type="text"
                      >查看图片</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="操作日志" name="second">
            <el-table
              :data="oplogData"
              border
              :height="tableHeigth_log"
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                prop="operatorUserName"
                align="center"
                label="操作人"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operatorTime"
                align="center"
                :show-overflow-tooltip="true"
                label="操作时间"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="operatorDeviceShow"
                align="center"
                label="操作设备"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="operatorName"
                align="center"
                label="操作"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="operatorDescribe"
                align="center"
                label="日志描述"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="isShowShow"
                align="center"
                label="前端可见"
                :show-overflow-tooltip="true"
                min-width="80"
              >
              </el-table-column>
              <el-table-column
                prop="comment"
                align="center"
                label="备注"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="documentStatus"
                align="center"
                label="单据类型"
                :show-overflow-tooltip="true"
                min-width="80"
              >
              </el-table-column>
              <el-table-column
                prop="documentNumber"
                align="center"
                label="单据单号"
                :show-overflow-tooltip="true"
                min-width="140"
              >
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <paging ref="pags" :pageNum="pageStart_log" :total="pageCount_log" :sizeList="sizeList"
              :size="pageTotal_log" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
            </paging> -->
          </el-tab-pane>
          <el-tab-pane label="财务应收" name="third">
            <el-descriptions title="财务信息" :column="4" border size="medium">
              <el-descriptions-item label="应收金额"
                >{{ moneyDetailData.financeInInfoVo.settlementAmount }}
              </el-descriptions-item>
              <el-descriptions-item label="收款状态"
                >{{ moneyDetailData.financeInInfoVo.writeoffStatusShow }}
              </el-descriptions-item>
              <el-descriptions-item label="付款方式"
                >{{ moneyDetailData.financeInInfoVo.paymentMethodShow }}
              </el-descriptions-item>
              <el-descriptions-item label="核销备注"
                >{{ moneyDetailData.financeInInfoVo.writeoffComment }}
              </el-descriptions-item>
              <el-descriptions-item label="核销图片">
                <div
                  v-if="moneyDetailData.financeInInfoVo.fileVos"
                  @click="lookImgsArr(moneyDetailData.financeInInfoVo.fileVos)"
                >
                  查看图片
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="核销时间"
                >{{ moneyDetailData.financeInInfoVo.writeoffTime }}
              </el-descriptions-item>
              <el-descriptions-item label="核销人"
                >{{ moneyDetailData.financeInInfoVo.writeoffUserName }}
              </el-descriptions-item>
              <el-descriptions-item label="审核时间"
                >{{ moneyDetailData.financeInInfoVo.approveTime }}
              </el-descriptions-item>
              <el-descriptions-item label="审核人"
                >{{ moneyDetailData.financeInInfoVo.approverName }}
              </el-descriptions-item>
            </el-descriptions>
            <el-table
              show-summary
              :data="moneyDetailData.serviceVos"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column type="index" label="序号" width="50">
              </el-table-column>
              <el-table-column
                prop="amount"
                align="center"
                label="金额"
                :show-overflow-tooltip="true"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="serviceName"
                align="center"
                :show-overflow-tooltip="true"
                label="费项"
                min-width="160"
              >
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="快递列表" name="fourth">
            <el-table
              show-summary
              :data="BillPackExpressListV2"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <!-- <el-table-column type="index" label="序号" width="50"></el-table-column> -->
              <el-table-column
                prop="courierNumber"
                align="center"
                label="快递单号"
                :show-overflow-tooltip="true"
                min-width="120"
              >
              </el-table-column>
              <el-table-column
                prop="packageWeight"
                align="center"
                label="入库重量(kg)"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="itemName"
                align="center"
                label="物品名称"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="unitPrice"
                align="center"
                label="货值"
                min-width="80"
              ></el-table-column>
              <el-table-column
                prop="quantity"
                align="center"
                label="数量"
                min-width="80"
              ></el-table-column>
              <el-table-column
                prop="comment"
                align="center"
                label="备注"
                min-width="160"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="附 件" name="five">
            <el-table
              :data="BillPackFileListV2"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                align="center"
                label="操作时间"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="documentStatusShow"
                align="center"
                label="来源单据类型"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="comment"
                align="center"
                label="备注"
                min-width="160"
              ></el-table-column>
              <el-table-column
                prop="fileName"
                align="center"
                label="附件名称"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="fileUrl"
                align="center"
                label="查看附件"
                min-width="100"
              >
                <template slot-scope="scoped">
                  <el-button type="text" @click="lookImgs(scoped.row.fileUrl)"
                    >查看图片</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- 出入库记录 -->
          <el-tab-pane label="出入库记录" name="six" class="details">
            <el-table
              :data="BillPackInOutStockRecordListV2"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                prop="operationUserName"
                align="center"
                label="操作人"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="operationTime"
                align="center"
                label="操作时间"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="typeName"
                align="center"
                label="操作设备"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="operationTypeShow"
                align="center"
                label="操作类型"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="inStorageName"
                align="center"
                label="入库仓库"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="outStorageName"
                align="center"
                label="出库仓库"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="comment"
                align="center"
                label="备注"
                min-width="100"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- 出入库记录 -->
          <el-tab-pane label="客服跟踪" name="seven" class="details">
            <el-table
              :data="kefuDatasList"
              border
              stripe
              size="small"
              style="width: 100%"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
              ></el-table-column>
              <el-table-column
                prop="number"
                align="center"
                label="客户跟踪号"
                min-width="140"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="documentNumber"
                align="center"
                label="单据编号"
                min-width="120"
              ></el-table-column>
              <el-table-column
                prop="content"
                align="center"
                label="内容描述"
                :show-overflow-tooltip="true"
                min-width="160"
              >
              </el-table-column>
              <el-table-column
                prop="remarks"
                align="center"
                label="备注"
                min-width="100"
              ></el-table-column>

              <el-table-column
                prop="documentStatusShow"
                align="center"
                label="单据类型"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="isShowShow"
                align="center"
                label="是否展示"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="operationTime"
                align="center"
                label="发生时间"
                min-width="100"
              ></el-table-column>
              <el-table-column
                prop="createUserName"
                align="center"
                label="创建人"
                min-width="100"
              ></el-table-column>
              <el-table-column label="查看附件" width="80">
                <template slot-scope="scope">
                  <el-button
                    @click="lookFile(scope.row.recordId)"
                    type="text"
                    size="mini"
                    >查看附件</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 会员管理查询弹出层 -->
    <el-dialog
      title="会员管理"
      :visible.sync="isDT"
      width="50%"
      :close-on-click-modal="false"
      @close="resetData('vf')"
    >
      <div class="newForm">
        <div class="formS">
          <el-form
            ref="vf"
            size="mini"
            :model="vf"
            :rules="vfr"
            label-width="80px"
          >
            <el-row>
              <el-col :span="19" style="display: flex">
                <div class="queryItem1">
                  <el-form-item label="会员号:" prop="memberId">
                    <el-input v-model="vf.memberId"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="会员名称:" prop="nickname">
                    <el-input v-model="vf.nickname"></el-input>
                  </el-form-item>
                </div>
                <!-- <div class="queryItem1">
                  <el-form-item label="仓库:" prop="memberId">
                    <el-select
                      v-model="vf.memberId"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-form-item>
                </div> -->
              </el-col>
              <el-col :span="1">
                <el-form-item label-width="10px">
                  <el-button type="warning" @click="cxEdit">查 询</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tableBox">
          <el-table
            :data="ut"
            size="mini"
            max-height="400px"
            border
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="memberId"
              label="会员号"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="会员名称"
              align="center"
              min-width="120"
            >
            </el-table-column>

            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="color: #ef7c1b"
                  size="mini"
                  @click="xz(scope.row)"
                  >选 择
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageStart_u"
            :total="pageCount_u"
            :sizeList="sizeList"
            :size="pageTotal_u"
            @handleSizeChange="psc_u"
            @handleCurrentChange="pcc_u"
          ></paging>
        </div>
      </div>
    </el-dialog>
    <!-- 查询轨迹 -->
    <el-dialog
      title="查询轨迹"
      :visible.sync="isDT_query"
      width="56%"
      :close-on-click-modal="false"
      @close="resetData('eidtQuery')"
    >
      <div class="queryBox">
        <el-form
          ref="eidtQuery"
          size="mini"
          :inline="true"
          :model="eidtQuery"
          label-width="80px"
        >
          <el-form-item label="快递单号">
            <el-input v-model="eidtQuery.courierNumber" clearable></el-input>
          </el-form-item>

          <el-form-item label="快递公司编码">
            <el-select v-model="eidtQuery.expressCode" clearable filterable>
              <el-option
                v-for="item in kuaidiList"
                :key="item.expressNumber"
                :label="item.expressName"
                :value="item.expressNumber"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="eidtQuery.phone" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="eidtQuerySubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="showBox">
        <div class="countryGrounp">
          <div class="countryItem">
            {{ formQuery.areaCountryName }}
          </div>
          <div class="number">
            {{ formQuery.courierNumber }}
          </div>
        </div>
      </div>
      <div class="contentBox">
        <el-table
          :data="formQuery.data"
          size="mini"
          max-height="500"
          stripe
          highlight-current-row
          border
          style="width: 100%"
        >
          <el-table-column
            align="center"
            prop="time"
            label="时间"
            min-width="30%"
          >
          </el-table-column>
          <el-table-column
            prop="context"
            align="center"
            :show-overflow-tooltip="true"
            label="轨迹"
            min-width="70%"
          >
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 客户加收弹窗 -->
    <el-dialog
      :title="titleH"
      :visible.sync="isDT_addC"
      width="50%"
      center
      :close-on-click-modal="false"
      @close="resetData('customerForm')"
    >
      <div class="newForm customerForm">
        <el-form
          ref="customerForm"
          :model="customerForm"
          :rules="customerRules"
          label-width="100px"
        >
          <!-- <div class="queryItem1">
            <el-form-item label="加收编号:">
              <el-input
                v-model="customerForm.courierNumber"
                readonly
                placeholder="添加后自动生成"
              ></el-input>
            </el-form-item>
          </div> -->
          <template v-if="isCustomer">
            <div class="queryItem1" @click="changeVip">
              <el-form-item label="会员号:" prop="userName">
                <el-input
                  v-model="customerForm.userName"
                  readonly
                  placeholder="请选择"
                ></el-input>
              </el-form-item>
            </div>
          </template>
          <template v-else>
            <div class="queryItem1">
              <el-form-item label="渠道代理:" prop="customerAgentId">
                <el-select
                  v-model="customerForm.customerAgentId"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in agentArr"
                    :key="item.customerAgentId"
                    :label="item.agentName"
                    :value="item.customerAgentId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </template>
          <div class="queryItem1">
            <el-form-item label="来源单据:" prop="financeDocumentstatusId">
              <el-select
                v-model="customerForm.financeDocumentstatusId"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in lyArr"
                  :key="item.financeDocumentstatusId"
                  :label="item.documentStatusName"
                  :value="item.financeDocumentstatusId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="来源单号:" prop="documentNumber">
              <el-input
                v-model="customerForm.documentNumber"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <!--<div class="queryItem1">-->
          <!--<el-form-item label="会员名称:" prop="nickname">-->
          <!--<el-input v-model="customerForm.nickname"></el-input>-->
          <!--</el-form-item>-->
          <!--</div>-->
          <div class="queryItem1">
            <el-form-item label="加收项目:" prop="itemServiceId">
              <el-select
                v-model="customerForm.itemServiceId"
                @change="changeServe"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in serveArr"
                  :key="item.itemServiceId"
                  :label="item.title"
                  :value="item.itemServiceId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="金额:" prop="settlementAmount">
              <el-input v-model="customerForm.settlementAmount"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="仓库:">
              <el-select
                class="input"
                v-model="customerForm.storageId"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in storageList"
                  :key="item.storageName"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="queryItem1">
            <el-form-item label="加收备注:" prop="comment">
              <el-input v-model="customerForm.comment"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="mini" @click="addCustomerM"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 大图预览 -->
    <el-dialog
      :title="titleH"
      :visible.sync="bigImgsShow"
      width="50%"
      center
      :close-on-click-modal="false"
    >
      <div class="">
        <div v-for="(item, index) in srcList" :key="index">
          <el-image
            style="width: 180px; height: 180px; margin: 10px"
            :src="item"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="mini" @click="bigImgsShow = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>

    <!-- 上传附件 -->
    <el-dialog
      title="上传附件"
      :visible.sync="UpLoadfileShow"
      @close="closeDia"
      width="50%"
      center
      :close-on-click-modal="false"
    >
      <div class="">
        <el-form
          class="upLoadForm"
          ref="forms"
          :model="formFileData"
          label-width="100px"
        >
          <el-form-item label="申请打包单号" style="width: 49%">
            <el-select
              class="input"
              v-model="formFileData.billPackId"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in expressData"
                :key="index"
                :label="item.billPackNumber"
                :value="item.billPackId + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附件类型" style="width: 49%">
            <el-select
              class="input"
              v-model="formFileData.documentStatus"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in documentStatusList"
                :key="index"
                :label="item.showName"
                :value="item.statusVal"
                :disabled="
                  item.statusVal == 'bill_kf_track_record_v2:document_status'
                "
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否展示" style="width: 98%">
            <el-select
              class="input"
              v-model="formFileData.isShow"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in isShowList"
                :key="index"
                :label="item.showName"
                :value="item.statusVal"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" style="width: 98%">
            <el-input
              placeholder="请输入"
              type="textarea"
              v-model="formFileData.comment"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传附件">
            <el-upload
              class="upload-demo"
              :http-request="uploadSectionFile"
              :before-upload="handleBeforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :file-list="fileLists"
              drag
              :action="upLoadUrl"
              multiple
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传jpg/png/jpeg文件，且不超过5M
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="mini" @click="confirmUpload"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showFilse" width="50%">
      <el-image
        style="width: 600px; height: 600px"
        :src="fileurls"
        :preview-src-list="srcList"
      >
      </el-image>
    </el-dialog>

    <el-dialog
      title="查看图片"
      :visible.sync="showBigImgs"
      append-to-body
      width="60%"
      center
    >
      <div class="wtl_displayFlex">
        <div class="imgItems" v-for="(item, index) in bigImgs" :key="index">
          <el-image
            style="width: 200px; height: 200px"
            :src="item.fileUrl"
            :preview-src-list="bigImgUrls"
          >
          </el-image>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showBigImgs = false">关闭</el-button>
      </div>
    </el-dialog>

    <addCustomerTarck
      :showDia1="showDia1"
      :documentNumbers="documentNumbers"
      :documentStatus="documentStatus"
      :isdisabledStatus="true"
      @confirmAdd="confirmAdd"
      @closeDia1="closeDia1"
    ></addCustomerTarck>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";
import addCustomerTarck from "@/components/CustomerServiceTrackingNew.vue";

export default {
  inject: ["reload"],
  components: { paging, addCustomerTarck },
  data() {
    return {
      upLoadUrl: this.common.uploadUrl,
      fileSize: 5, //文件最大5M
      fileLists: [],
      srcList: [],
      fileType: ["png", "jpg", "jpeg"],
      showFilse: false,
      fileurls: "",
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: "更多条件", //  输入框绑定动态class
      queryData: {
        billPackNumbers: "",
        channelNumbers: "",
        channelTransportId: "",
        memberId: "",
        outStockNumbers: "",
        invitationCode: "",
        packStatus: "",
        startTime: "",
        endTime: "",
      },
      channelList: [], //  渠道代理下拉列表
      storageList: [], //  仓库下拉列表
      kuaidiList: [], //快递公司下拉
      packageStatusList: [], //  包裹状态下拉列表
      testStatusList: [], //  验货状态下拉列表
      rejectionStatusList: [], //  拒签状态下拉列表
      expressData: [], //  快递单号表格
      tableHeigth: 0, //  表格高度
      tableHeigth_log: 0,
      pageStart: 1, //  分页配置
      pageTotal: 50,
      pageCount: 0,
      sizeList: [10, 20, 50, 100, 500],
      //    会员选择
      isDT: false,
      vf: {},
      vfr: {},
      ut: [],
      pageStart_u: 1, //  分页配置
      pageTotal_u: 50,
      pageCount_u: 0,
      //    查询轨迹
      isDT_query: false,
      eidtQuery: {
        courierNumber: "",
        expressCode: "",
        phone: "",
      }, //   查询表单
      formQuery: {},
      //    tab标签页
      activeName: "first",
      packageDetail: {
        baseInfoVo: {
          billPackNumber: "",
          memberId: "",
          memberName: "",
          currentStorageName: "",
          transportName: "",
          channelName: "",
          channeAgentlName: "",
          channelNumber: "",
          shiftNumber: "",
          packageCount: "",
          packSureCount: "",
          totalWeight: "",
          dataVolume: "",
          totalVolume: "",
          billStorepackComment: "",
        },
        addressVo: {
          address: "",
          areaCountryName: "",
          areaName: "",
          companyName: "",
          fullName: "",
          phone: "",
          postalCode: "",
          provinceName: "",
          tel: "",
          userAddressId: "",
        },
        descPackageVo: [],
      },
      oplogData: [], //  操作日志表格数据
      moneyDetailData: {
        serviceVos: [],
        financeInInfoVo: {
          approveTime: "",
          approverName: "",
          fileVos: [],
          financeId: "",
          paymentMethod: "",
          paymentMethodShow: "",
          settlementAmount: "",
          writeoffComment: "",
          writeoffStatus: "",
          writeoffStatusShow: "",
          writeoffTime: "",
          writeoffUserName: "",
        },
      }, //  应收明细表格数据
      packageDetailsData: [], //  包裹明细表格数据
      BillPackExpressListV2: [], //  快递列表
      BillPackFileListV2: [], //  附件列表
      BillPackInOutStockRecordListV2: [], //  附件列表
      kefuDatasList: [], //客服跟踪
      pageStart_log: 1,
      pageTotal_log: 50,
      pageCount_log: 0,
      packageId: "",
      //    客户加收
      isDT_addC: false,
      customerForm: {
        settlementAmount: "",
        financeDocumentstatusId: "",
        customerAgentId: "",
        itemServiceId: "",
        comment: "",
        documentNumber: "",
        storageId: "",
        serviceName: "",
      },
      customerRules: {
        settlementAmount: [{ required: true, message: " ", trigger: "blur" }],
        userName: [{ required: true, message: " ", trigger: "blur" }],
        financeDocumentstatusId: [
          { required: true, message: " ", trigger: "change" },
        ],
        customerAgentId: [{ required: true, message: " ", trigger: "change" }],
      },
      lyArr: [],
      serveArr: [],
      isCustomer: true,
      agentArr: [],
      titleH: "",
      uids: "",

      timeType: "1",
      timeTypes: [
        //  时间类型
        {
          value: "0",
          label: "打包完成时间",
        },
        {
          value: "1",
          label: "打包申请时间",
        },
        {
          value: "2",
          label: "签收时间",
        },
      ],
      packageStatusArr: [],
      documentStatusList: [],
      isShowList: [
        {
          showName: "展示",
          statusVal: "1",
        },
        {
          showName: "不展示",
          statusVal: "0",
        },
      ],
      yunshuList: [],
      srcList: [], //打包明细图片列表
      bigImgsShow: false,

      // 客服跟踪的组件
      showDia1: false,
      documentNumbers: "",
      documentStatus: "",

      UpLoadfileShow: false, //上传附件

      formFileData: {
        isShow: "1",
        billPackId: "",
        comment: "",
        fileIds: [],
        fileName: "",
        documentStatus: "",
      },
      pageType: "",

      showBigImgs: false, //查看图片
      bigImgs: [],
      bigImgUrls: [],
      fileNameLists: [], //修改时的文件列表
    };
  },
  created() {
    this.getCangkuList(); //  获取仓库下拉列表
    this.expressChooseList(); //货区运快递公司
    this.getChannelList(); //  获取渠道代理下拉列表
    this.myGetStatusValList("package_v1.package_status", 1); //  包裹下拉
    this.myGetStatusValList("bill_inspection_v1.check_status", 2); //  验货下拉
    this.myGetStatusValList("bill_rejection_v1.handle_status", 3); //  拒签
    this.myGetStatusValList("bill_pack_v1.pack_status", 4); //  获取包裹状态
    this.myGetStatusValList("file_all_v2.document_status", 5); //文件来源单据类型
    this.getTransportMethodList(); //运输方式

    let endtime = tools.getCurrentDate(); //今天的时间;
    let sarttime = tools.getFlexDate(-30); //10天前
    this.queryData.startTime = sarttime;
    this.queryData.endTime = endtime;
  },
  mounted() {},
  activated() {
    let packid = this.$route.query.packageId || "";
    // this.pageType = this.$route.query.pageType || "";

    if (packid) {
      this.packageId = packid;
      this.queryData.courierNumber = this.$route.query.courierNumber;
      this.reloadData(); // 获取主要数据的几个方法
    }
    this.getExpressDataDaHuo();
  },
  watch: {},
  methods: {
    // 查看附件
    lookFile(id) {
      Api.getBillKfTrackRecordFileV2({ recordId: id }).then((res) => {
        let result = res.data.result || [];
        if (result.length == 0) {
          this.$message.warning("当前跟踪单没有相关的文件");
        } else {
          this.showBigImgs = true;
          this.bigImgs = result;
          this.bigImgUrls = result.map((item) => {
            return item.fileUrl;
          });
        }
      });
    },

    handleRemove(file, fileList) {
      this.fileLists = fileList || [];
    },
    handlePictureCardPreview(file) {
      console.log(file);
      console.log(this.fileLists);
      this.showFilse = true;
      this.fileurls = file.url;
      this.srcList = this.fileLists.map((item) => {
        return item.url;
      });
    },
    // 上传接口
    uploadSectionFile(param) {
      const file = param.file;
      const fileName = file.name;
      const form = new FormData(); //  根据后台需求数据格式
      form.append("file", file); //  文件对象
      form.append("fileName", fileName); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // return;
      Api.uploadFile(form).then((res) => {
        if (res.data.status === "success") {
          //   this.fileNameList.push(res.data.result.fileName);
          let obj = {
            name: res.data.result.fileName,
            url: res.data.result.url,
          };
          this.fileLists.push(obj);
          console.log(this.fileLists);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      console.log(file);
      console.log(this.fileType);
      // 校检文件类型
      if (this.fileType && file) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        let isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        console.log(file);
        console.log(isTypeOk);
        if (!isTypeOk && file) {
          this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize && file) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },

    confirmUpload() {
      let fileIds = [];
      this.fileLists.forEach((item) => {
        fileIds.push(item.name);
      });
      let param = {
        billPackId: this.formFileData.billPackId,
        comment: this.formFileData.comment,
        documentStatus: this.formFileData.documentStatus,
        fileIds: fileIds,
        isShow: this.formFileData.isShow,
      };
      param.sign = tools.getSign(param);
      Api.uploadWaybillFileV2(param).then((res) => {
        this.UpLoadfileShow = false;
      });
    },

    //客服跟踪 (跳转新建问题)
    kehugenzong() {
      // this.$router.push({
      //   path: "/exceptionhandling/NewQuestion",
      // });
      // if (this.packageDetail.baseInfoVo.billPackNumber) {
      //   this.documentNumbers = this.packageDetail.baseInfoVo.billPackNumber;
      // }
      this.documentNumbers = this.billPackNumber;
      this.documentStatus = "bill_pack_v1:document_status"; //申请打包单据
      this.showDia1 = true;
    },
    uploadFIle() {
      this.UpLoadfileShow = true;
      this.formFileData.billPackId = this.packageId;
    },
    closeDia() {
      this.fileLists = [];
      this.formFileData = {
        isShow: "1",
        billPackId: "",
        comment: "",
        fileIds: [],
        fileName: "",
        documentStatus: "",
      };
    },
    // 提交新建
    confirmAdd(formName) {
      this.showDia1 = false;
      this.getData();
    },
    // 关闭重置弹窗数据
    closeDia1(e) {
      this.showDia1 = e || false;
      this.documentNumbers = "";
    },
    // 数组
    lookImgsArr(imgs) {
      console.log(imgs);
    },
    //单图
    lookImgs(url) {
      if (url) {
        let arrs = [];
        arrs.push(url);
        this.srcList = arrs;
        this.bigImgsShow = true;
      } else {
        this.$message.warning("打开失败,没有检测到图片地址");
        return false;
      }
    },
    // 打包明细查看图片
    getImgsId(id) {
      console.log(id);
      Api.getStorepackDescFileV2({ stockPackDescId: id })
        .then((res) => {
          let imgs = res.data.result || [];
          let arrs = [];
          if (imgs.length > 0 && imgs[0].fileUrl) {
            imgs.forEach((element) => {
              arrs.push(element.fileUrl);
            });
            this.srcList = arrs;
            this.bigImgsShow = true;
          } else {
            this.$message.warning("该包裹没有打包图片");
          }
        })
        .catch(() => {});
    },

    // 获取主要数据
    reloadData() {
      // this.getPackageDetails() //详情
      // this.getPackageLogOperates(); //  获取操作日志
      // this.getPackageItems(); //  获取包裹明细
      // this.myGetPackageFinanceInDetail(); //   获取财务应收
      // this.getBillPackExpressListV2(); //   快递列表
      // this.getBillPackFileListV2(); //   附件列表
      // this.getBillPackInOutStockRecordListV2(); //   出入库记录
      let val = this.activeName;
      switch (val) {
        case "first":
          this.getPackageDetails(); //详情
          break;
        case "second":
          this.getPackageLogOperates(); //操作日志
          break;
        case "third":
          this.myGetPackageFinanceInDetail(); //财务应收
          break;
        case "fourth":
          this.getBillPackExpressListV2(); //快递列表
          break;
        case "five":
          this.getBillPackFileListV2(); //附件
          break;
        case "six":
          this.getBillPackInOutStockRecordListV2(); //出入库记录
          break;
        case "seven":
          this.kefuDatas(); //出入库记录
          break;
      }
    },
    clickActiveName(e) {
      let val = e.name;
      switch (val) {
        case "first":
          this.getPackageDetails(); //详情
          break;
        case "second":
          this.getPackageLogOperates(); //操作日志
          break;
        case "third":
          this.myGetPackageFinanceInDetail(); //财务应收
          break;
        case "fourth":
          this.getBillPackExpressListV2(); //快递列表
          break;
        case "five":
          this.getBillPackFileListV2(); //附件
          break;
        case "six":
          this.getBillPackInOutStockRecordListV2(); //出入库记录
          break;
        case "seven":
          this.kefuDatas(); //出入库记录
          break;
      }
    },

    //获取运输方式
    getTransportMethodList() {
      Api.getTransportMethodList().then((res) => {
        this.yunshuList = res.data.result.data || [];
      });
    },
    //货区运快递公司
    expressChooseList() {
      Api.expressChooseList().then((res) => {
        if (res.data.status === "success") {
          this.kuaidiList = res.data.result || [];
        }
      });
    },
    getCangkuList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storageList = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表`);
        }
      });
    },
    getChannelList() {
      //  获取渠道代理下拉列表
      Api.channelAgentChooseList().then((res) => {
        if (res.data.status === "success") {
          this.channelList = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--渠道代理下拉列表`);
        }
      });
    },
    //  获取公共状态下拉列表
    myGetStatusValList(status, type) {
      Api.getStatusValList({ tableAndFieldName: status }).then((res) => {
        if (res.data.status === "success") {
          switch (type) {
            case 1:
              this.packageStatusList = res.data.result; //  包裹
              break;
            case 2:
              this.testStatusList = res.data.result; //  验货
              break;
            case 3:
              this.rejectionStatusList = res.data.result; //  拒签
              break;
            case 4:
              this.packageStatusArr = res.data.result; //  获取包裹状态
              break;
            case 5:
              this.documentStatusList = res.data.result; //  获取包裹状态
              break;
          }
        } else {
          this.$message.error(`${res.data.message}--公共状态`);
        }
      });
    },
    // 请选择时间类型
    timestatus(e) {
      let Timedata = this.Timedata;
      Timedata.pageStart = this.homePage;
      Timedata.pageTotal = this.articless;
      // if (e) {
      if (e == "0") {
        //   打包完成时间
        this.timelist = [];
        this.timelistdata = [];
      } else if (e == "1") {
        //   打包申请时间
        this.timelist = [];
        this.timelistdata = [];
      }
    },

    // 获取左边列表(大货的)
    getExpressDataDaHuo() {
      //  获取数据
      let courierNumberList =
        tools.getOrderNum(this.queryData.billPackNumbers) || []; //打包批次号数组
      let channelNumbers =
        tools.getOrderNum(this.queryData.channelNumbers) || []; //转单号
      let number5 = tools.getOrderNum(this.queryData.outStockNumbers) || []; //出库单号

      let startBillPackTime = ""; //打包申请
      let endBillPackTime = "";
      let startFinishPackTime = ""; //打包完成
      let endFinishPackTime = "";
      let startSignForTime = ""; //签收时间
      let endSignForTime = "";

      let startTime = this.queryData.startTime;
      let endTime = this.queryData.endTime;
      if (startTime) {
        startTime = startTime + " 00:00:00";
      }
      if (endTime) {
        endTime = endTime + " 23:59:59";
      }

      if (this.timeType == "1") {
        // 打包申请时间
        startBillPackTime = startTime;
        endBillPackTime = endTime;
      } else if (this.timeType == "2") {
        // 签收时间
        startSignForTime = startTime;
        endSignForTime = endTime;
      } else {
        // 打包完成时间
        startFinishPackTime = startTime;
        endFinishPackTime = endTime;
      }

      let param = {
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
        billPackNumbers: courierNumberList,
        outStockNumbers: number5,
        channelNumbers: channelNumbers,
        channelTransportId: this.queryData.channelTransportId,
        invitationCode: this.queryData.invitationCode,
        memberId: this.queryData.memberId,
        packStatus: this.queryData.packStatus,
        startBillPackTime: startBillPackTime,
        endBillPackTime: endBillPackTime,
        startFinishPackTime: startFinishPackTime,
        endFinishPackTime: endFinishPackTime,
        startSignForTime: startSignForTime,
        endSignForTime: endSignForTime,
        billPackType: "da_huo:bill_pack_type", //大货单据
      };
      Api.getPackPackagetListV2(param).then((res) => {
        if (res.data.status === "success") {
          this.className = res.data.result.className || "";
          this.expressData = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;
          this.fetTableHeight();

          // 没有id的时候
          if (!this.packageId) {
            if (this.expressData.length == 0) {
              return false;
            }
            this.packageId = this.expressData[0].billPackId;
            this.reloadData();
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    getPackageDetails() {
      //  获取包裹详情
      Api.getPackPackagetDetailV2({
        billPackId: this.packageId,
      }).then((res) => {
        this.packageDetail = res.data.result;
        this.billPackNumber = res.data.result.baseInfoVo.billPackNumber || "";
      });
    },
    getPackageLogOperates() {
      //  获取操作日志
      Api.getBillPackOperateLogV2({
        billPackId: this.packageId,
        // pageStart: this.pageStart_log,
        // pageTotal: this.pageTotal_log,
      }).then((res) => {
        if (res.data.status === "success") {
          this.oplogData = res.data.result || [];
          this.pageCount_log = res.data.result.pageCount;
        } else {
          this.$message.error(`${res.data.message}--操作日志`);
        }
      });
    },

    myGetPackageFinanceInDetail() {
      //  获取包裹详情-应收明细
      Api.getBillPackFinanceInV2({
        billPackId: this.packageId,
      }).then((res) => {
        let moneyDetailData = res.data.result || {};
        this.moneyDetailData = moneyDetailData;
        this.moneyDetailData.serviceVos = moneyDetailData.serviceVos || [];
        this.moneyDetailData.financeInInfoVo =
          moneyDetailData.financeInInfoVo || {};
      });
    },
    // 快递列表
    getBillPackExpressListV2() {
      Api.getBillPackExpressListV2({
        billPackId: this.packageId,
      }).then((res) => {
        this.BillPackExpressListV2 = res.data.result || [];
      });
    },
    //  附件列表
    getBillPackFileListV2() {
      Api.getBillPackFileListV2({
        billPackId: this.packageId,
      }).then((res) => {
        this.BillPackFileListV2 = res.data.result || [];
      });
    },
    //  出入库记录
    getBillPackInOutStockRecordListV2() {
      Api.getBillPackInOutStockRecordListV2({
        billPackId: this.packageId,
      }).then((res) => {
        this.BillPackInOutStockRecordListV2 = res.data.result || [];
      });
    },
    //  客服跟踪
    kefuDatas() {
      let formData = {
        documentNumber: this.billPackNumber,
        documentStatus: "bill_pack_v1:document_status",
        // content: this.queryData.content,
        // isShow: this.queryData.isShow,
        // endCreateTime: endCreateTime,
        // startCreateTime: startCreateTime,
        // endOperationTime: endOperationTime,
        // startOperationTime: startOperationTime,
        // pageStart: this.pageNum,
        // pageTotal: this.size,
      };

      Api.getBillKfTrackRecordListV2(formData).then((res) => {
        if (res.data.status == "success") {
          this.kefuDatasList = res.data.result.data || [];

          this.fetTableHeight();
        }
      });
    },
    getPackageItems() {
      //  获取包裹明细
      Api.packageItems({
        packageId: this.packageId,
      }).then((res) => {
        if (res.data.status === "success") {
          this.packageDetailsData = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--包裹明细`);
        }
      });
    },

    queryForKD100() {
      //  查询快递轨迹
      let param = {
        courierNumber: this.eidtQuery.courierNumber,
        expressCode: this.eidtQuery.expressCode,
        phone: this.eidtQuery.phone || "",
      };
      param.sign = tools.getSign(param);
      Api.expressQueryForKD100(param).then((res) => {
        if (res.data.status === "success") {
          this.formQuery = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--查询快递轨迹`);
        }
      });
    },
    eidtQuerySubmit() {
      this.queryForKD100();
    },
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },
    queryBtn_cz() {
      //  重置
      this.queryData = {
        billPackNumbers: "",
        channelNumbers: "",
        channelTransportId: "",
        memberId: "",
        outStockNumbers: "",
        invitationCode: "",
        packStatus: "",
        startTime: "",
        endTime: "",
      };
      this.timeType = "1";
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageStart = 1;
      this.getExpressDataDaHuo();
    },
    psc(val) {
      //  表格分页-当前页面总条数
      this.pageTotal = val;
      this.getExpressDataDaHuo();
    },
    pcc(val) {
      //  表格分页-页码
      this.pageStart = val;
      this.getExpressDataDaHuo();
    },
    dblclick(row) {
      console.log(row);
      this.packageId = row.billPackId;
      this.billPackNumber = row.billPackNumber;
      this.reloadData();
    },

    //   -------- 编辑会员 -----------
    changeVip() {
      //  选择会员名称
      this.getVipList();
      this.isDT = true;
    },

    getVipList() {
      //  获取会员列表
      this.vf.pageStart = this.pageStart_u;
      this.vf.pageTotal = this.pageTotal_u;
      Api.getMemberList(this.vf).then((res) => {
        if (res.data.status === "success") {
          this.ut = res.data.result.data;
          this.pageCount_u = res.data.result.pageCount || 0;
        }
      });
    },

    xz(data) {
      //  会员表格选择事件
      if (this.isDT_addC) {
        this.customerForm.userId = data.id;
        this.customerForm.userName = data.memberId;
      } else {
        this.queryData.memberId = data.memberId;
        this.uids = data.id;
      }
      this.isDT = false;
    },
    cxEdit() {
      //  查询按钮
      this.pageStart_u = 1;
      this.getVipList();
    },
    psc_u(val) {
      //  分页事件
      this.pageTotal_u = val;
      this.getVipList();
    },
    pcc_u(val) {
      //  分页事件
      this.pageStart_u = val;
      this.getVipList();
    },
    handleSizeChange(val) {
      this.pageTotal_log = val;
      this.getPackageLogOperates();
    },
    handleCurrentChange(val) {
      this.pageTotal_log = val;
      this.getPackageLogOperates();
    },
    handleClose(num) {
      //  会员弹出框关闭前回调事件
      this.resetData("vf");
    },
    resetData(formName) {
      this.$refs[formName].resetFields();
    },

    //客户追踪
    NewQuestion() {
      this.$router.push("/exceptionhandling/NewQuestion?pageType=" + "track");
    },

    queryTrack() {
      //  轨迹查询按钮
      this.eidtQuery.courierNumber = this.packageDetail.courierNumber || "";
      // this.queryForKD100()
      this.isDT_query = true;
    },

    getLyArr() {
      //  获取来源单据
      Api.financeDocumentstatusChooseList().then((res) => {
        this.lyArr = res.data.result;
      });
    },
    getServeArr() {
      //  获取服务项
      Api.itemServiceListAll({ serviceStatus: "shou:direction_status" }).then(
        (res) => {
          this.serveArr = res.data.result;
        }
      );
    },

    getAgentArr() {
      //  获取渠道代理
      Api.channelAgentChooseList().then((res) => {
        this.agentArr = res.data.result;
      });
    },
    kehujiashou(num) {
      //  客户加收
      if (num === 1) {
        this.isCustomer = true;
        this.titleH = "新建客户加收";
      } else {
        this.getAgentArr();
        this.titleH = "新建代理加收";
        this.isCustomer = false;
      }
      this.getLyArr();
      this.getServeArr();
      this.isDT_addC = true;
    },

    changeServe(val) {
      //  选择服务项的自动填写事件
      let index = this.serveArr.findIndex((v) => v.itemServiceId === val);
      this.customerForm.settlementAmount = this.serveArr[index].unitPrice;
      this.customerForm.serviceName = this.serveArr[index].title;
    },

    addCustomerM() {
      //  客户加收提交
      let index = this.lyArr.findIndex(
        (v) =>
          v.financeDocumentstatusId ===
          this.customerForm.financeDocumentstatusId
      );
      this.customerForm.financeDocumentstatusName =
        this.lyArr[index].documentStatusName;
      if (this.isCustomer) {
        delete this.customerForm.userName;
        this.customerForm.sign = tools.getSign(this.customerForm);
        let param1 = {
          financeDocumentstatusId: this.customerForm.financeDocumentstatusId,
          financeDocumentstatusName:
            this.customerForm.financeDocumentstatusName,
          settlementAmount: this.customerForm.settlementAmount,
          userId: this.customerForm.userId,
          comment: this.customerForm.comment,
          documentNumber: this.customerForm.documentNumber,
          itemServiceId: this.customerForm.itemServiceId,
          serviceName: this.customerForm.serviceName,
          storageId: this.customerForm.storageId,
        };
        let sign = tools.getSign(param1);
        param1.sign = sign;
        Api.addCustomerAdditional(param1).then((res) => {
          if (res.data.status === "success") {
            this.$message.success(res.data.message);
            this.isDT_addC = false;
          } else {
            this.$message.error(res.data.message);
          }
        });
      } else {
        let param2 = {
          financeDocumentstatusId: this.customerForm.financeDocumentstatusId,
          financeDocumentstatusName:
            this.customerForm.financeDocumentstatusName,
          settlementAmount: this.customerForm.settlementAmount,
          customerAgentId: this.customerForm.customerAgentId,
          comment: this.customerForm.comment,
          documentNumber: this.customerForm.documentNumber,
          itemServiceId: this.customerForm.itemServiceId,
          serviceName: this.customerForm.serviceName,
          storageId: this.customerForm.storageId,
          customerAgentNumber: this.customerForm.customerAgentNumber,
        };
        let sign = tools.getSign(param2);
        param2.sign = sign;
        Api.addAgentAdditional(param2).then((res) => {
          if (res.data.status === "success") {
            this.$message.success(res.data.message);
            this.isDT_addC = false;
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },

    //代理加收 (跳转代理加收)
    dailijiashou() {
      this.$router.push({
        path: "/PayableManagement/AgencyApplication",
      });
    },

    //        ----------   计算表格高度    -------------

    resetHeight() {
      //  重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        this.tableHeigth_log = 0;
        resolve();
      });
    },

    fetTableHeight() {
      // 设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight() - 50;
        this.tableHeigth_log = this.getHeight() - 100;
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },

    getHeight() {
      // 获取表格高度
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 0; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 30;
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
  },
};
</script>

<style scoped lang="scss">
.contentInner {
  width: 100%;
  display: flex;

  .rightInner {
    width: 200px;
    margin-right: 16px;
    cursor: pointer;
  }

  .leftInner {
    width: 80%;

    /deep/ .el-tabs__item {
      height: 36px;
      line-height: 36px;
      background-color: #f4f4f4;
    }

    /deep/ .el-tabs__content {
      padding: 16px;
    }

    /deep/ .el-descriptions {
      margin-bottom: 10px;
    }
  }
}

/*
    查询轨迹-查询表单
    */
.queryBox {
  margin-top: 20px;
}

.showBox {
  margin-left: 20px;

  .countryGrounp {
    display: flex;

    .countryItem {
      min-width: 100px;
      background-color: #cfcfcf;
      padding: 10px;
    }

    .number {
      min-width: 100px;
      background-color: #ababab;
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #fff;
    }
  }
}

.contentBox {
  width: 80%;
  margin-top: 20px;
}

.customerForm {
  .queryItem1 {
    margin: 0 auto;
    width: 60%;
    margin-bottom: 10px;
  }
}

.titles1 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.activeLeft {
  color: #f0a261;
}

.upLoadForm {
  display: flex;
  flex-wrap: wrap;

  /deep/ .el-upload-dragger .el-icon-upload {
    margin: 40px 0 10px;
  }

  /deep/ .el-upload {
    line-height: 40px;
  }
}
</style>
